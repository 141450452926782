/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.home-hero-slider .owl-carousel').owlCarousel({
          items: 1
        });
        // if ( $('#billing_country').length > 0 ) {
        //   $('#billing_country, #billing_state').select2();
        // }
        $('.upsell-product .product-item-name').matchHeight();
        $('.product-item .product-item-name').matchHeight();

        $('.product-list-link .tbt-title').matchHeight();
        $('.upsell-product .tbt-title').matchHeight();

        $('.product-list-link .attr-list').matchHeight();
        $('.upsell-product .attr-list').matchHeight();

        if ( $('#sbi_mod_error').length > 0 && ! $('body').hasClass('admin-bar') ) {
          $('.tbt-insta-feed').hide();
        }

        this.lazy_section();
        
        
        var upsell = $('.tbt-more-like .upsell-product.owl-carousel');
        upsell.owlCarousel({
          items: 4,
          margin: 30,
          nav: true,
          loop: true,
          responsive : {
            // breakpoint from 0 up
            0 : {
              items: 2,
            },
            // breakpoint from 768 up
            768 : {
              items: 4,
            }
          }
        });

        upsell.on('changed.owl.carousel', function(event) {
          $('.upsell-product .product-item-name').matchHeight();
          $('.upsell-product .tbt-title').matchHeight();
        });
        $('.tbt-load-more-cnt .product-item-name').matchHeight();
        $('.tbt-load-more-cnt .tbt-title').matchHeight();

        $(document).on('click','.lmp_button ', function(){
          setTimeout(function(){
            $('.product-list-link .tbt-title').matchHeight();
            $('.upsell-product .tbt-title').matchHeight();

            $('.product-list-link .attr-list').matchHeight();
            $('.upsell-product .attr-list').matchHeight();
          }, 300);
          setTimeout(function(){
            $('.product-list-link .tbt-title').matchHeight();
            $('.upsell-product .tbt-title').matchHeight();

            $('.product-list-link .attr-list').matchHeight();
            $('.upsell-product .attr-list').matchHeight();
          }, 500);
          setTimeout(function(){
            $('.product-list-link .tbt-title').matchHeight();
            $('.upsell-product .tbt-title').matchHeight();

            $('.product-list-link .attr-list').matchHeight();
            $('.upsell-product .attr-list').matchHeight();
          }, 1000);
          setTimeout(function(){
            $('.product-list-link .tbt-title').matchHeight();
            $('.upsell-product .tbt-title').matchHeight();

            $('.product-list-link .attr-list').matchHeight();
            $('.upsell-product .attr-list').matchHeight();
          }, 2000);
          setTimeout(function(){
            $('.product-list-link .tbt-title').matchHeight();
            $('.upsell-product .tbt-title').matchHeight();

            $('.product-list-link .attr-list').matchHeight();
            $('.upsell-product .attr-list').matchHeight();
          }, 3000);
        });

        $('#xoo_el_reg_terms').closest('label').addClass('xoo_el_reg_terms_group');



        $('.woocommerce-MyAccount-content').clone().appendTo( $('.woocommerce-MyAccount-navigation li.is-active') ).addClass('accordion-ordered');
        $('.woocommerce-MyAccount-navigation li.is-active').closest('nav').addClass('accordion-ordered');


        $('.tbt-carousel.owl-carousel').owlCarousel({
          items: 3,
          margin: 30,
          nav: false,
          loop: true,
          center: false,
          responsive : {
            // breakpoint from 0 up
            0 : {
              items: 1,
              dots: true
            },
            // breakpoint from 768 up
            768 : {
              items: 3,
              dots: false
            }
          }
        });

        $(document).on('click','.product-category .woocommerce-pagination a.page-numbers.next',function(e){
          e.preventDefault();
          var nest_post_link = $(this).attr('href');
          $(this).addClass('disabled').prop('disabled',true).attr('data-loading','Loading...');
          var current_pagination = $(this).closest('.woocommerce-pagination');
          $('#tbt-loadmore-container').load(nest_post_link + ' .product-listing-general .tbt-load-more-cnt', function(){
            current_pagination.remove();
            $('#tbt-loadmore-container .tbt-load-more-cnt > .row').appendTo('.product-listing-general .tbt-load-more-cnt');
            $('#tbt-loadmore-container').empty();
          });

          
        });
        

        $(".main-site-header").headroom({
          "offset": 250,
          "tolerance": 5,
          "classes": {
            "initial": "animated",
            "pinned": "slideDown",
            "unpinned": "slideUp",
          },
        });

        $('.tbt-more-like .crosssell-product.owl-carousel').owlCarousel({
          items: 2,
          margin: 30,
          nav: true,
          loop: true,
          responsive : {
            // breakpoint from 0 up
            0 : {
              items: 2,
            },
            // breakpoint from 768 up
            768 : {
              items: 2,
            }
          }
        });
        $(document).find('.tbt-taste-form.tbt-product-varion-form input[type="radio"]:checked').addClass('tbt-default-selection');

        $(document).on('change','.tbt-taste-form.tbt-product-varion-form input[type="radio"]',function(e){
          if( $(this).closest('tr.attribute-pa_quantity').length > 0 ) {
            if ( $(this).closest('label.btn').find('tbt_price_per_bottle').length <= 0 ) {
              var parent_row = $(this).closest('label.btn').addClass('price-loaded');
              
              var price_per_bottle = $(document).find('.woocommerce-variation-price .tbt_price_per_bottle');
              parent_row.find('.tbt_price_per_bottle').remove();
              
              price_per_bottle.appendTo($(this).closest('label.btn'));
            }
          }
        });

        
        setTimeout(function(){
          $('.tbt-taste-form.tbt-product-varion-form .attribute-pa_quantity input[type="radio"]').each(function(){
            $(this).attr('checked', true).trigger('click').trigger('change');
          });
          $('.tbt-taste-form.tbt-product-varion-form .attribute-pa_quantity input[type="radio"]').first().attr('checked', true).trigger('click').trigger('change');
          $('.tbt-taste-form.tbt-product-varion-form .attribute-pa_quantity input[type="radio"].tbt-default-selection').attr('checked', true).trigger('click').trigger('change');

        }, 1000);

        $(document).on('change','.tbt-taste-form input[type="checkbox"]',function(e){
          var attr      = $(this).closest('.btn-group-toggle').attr('data-attribute');
          var selected  = $('input[name="'+attr+'"]').val();

          if ( ! selected ) {
            selected = [];
          } else {
            selected = selected.split(",");
          }

          var value     = $(this).val();
          if ( $(this).is(':checked') ) {
            if ( ! selected.includes(value) ) {
              selected.push(value);
              selected = selected.toString();
              if ( selected.charAt(0) === ',' ) {
                selected = selected.substr(1);
              }
              $('input[name="'+attr+'"]').val( selected );
            }
          } else {
            var index = selected.indexOf(value);
            if ( index !== -1 ) {
              selected.splice(index, 1);
              selected = selected.toString();
              if ( selected.charAt(0) === ',' ) {
                selected = selected.substr(1);
              }
              $('input[name="'+attr+'"]').val( selected );
            }
          }          
        });

        $(document).on('click','.site-search-btn',function(e){
          e.preventDefault();
          var button = $(this);
          $('header.main-site-header .header-search-container').toggleClass('search-open');
          $('header.main-site-header .header-search-container').slideToggle('slow');
          var icon_class = $(this).find('i.icon').attr('class');
          var icon2_class = $(this).attr('data-closeicon');
  
          $(this).find('i.icon').slideToggle(200,function(){
            $(this).attr('class',icon2_class);
            button.attr('data-closeicon', icon_class);
            $(this).slideToggle(200);
          });
          
        });

        $(document).on('click','.show-more-button',function(e){
          e.preventDefault();
          var form_group = $(this).closest('.form-group');
          form_group.find('.btn').each(function(){
            console.warn($(this).is(':visible'));
            if ( ! $(this).is(':visible') ) {
              $(this).addClass('btn-collapsed').slideDown(200);
            }           
          });
          setTimeout(function(){
            $('html, body').animate({
              scrollTop: form_group.offset().top - 120
            }, 500);
          }, 200);
          $(this).hide();
          
        });

        $(document).on('click','#filter-trigger',function(e){
          e.preventDefault();
          $('body').addClass('filters-open');
        });
        $(document).on('click','.tbt-shop-sidebar-overlay, #tbt-close-filters',function(e){
          e.preventDefault();
          $('body').removeClass('filters-open');
        });

        $('.tbt-custom-range input[type="range"]').rangeslider({
          polyfill: false
        });

        function openNav() {
          document.getElementById("tbt-shop-filter").style.width = "250px";
          document.getElementById("tbt-main-wrap").style.marginLeft = "250px";
        }

        $('.tbt-product-details').each(function(){
          var select    = $(this).find('.custom-select');
          var qty       = $(this).find('.woocommerce-variation-add-to-cart .quantity input[name="quantity"]');
          var _selected = '';
          var selected_qty = parseInt(qty.val());
          if ( select.length > 0 && qty.length > 0 ) {
            var min = qty.attr('min');
            var max = qty.attr('max');
            for (i = min, len = max; i <= len; i++) { 
              if ( selected_qty === i ) {
                _selected = 'selected';
              } else {
                _selected = '';
              }
              select.append("<option value='"+i+"' "+ _selected +">"+i+"</option>");
            }
            
            select.addClass('qty-visible');
          }
        });

        $('body.woocommerce-cart .woocommerce-cart-form td.product-quantity').each(function(){
          var select    = $(this).find('.custom-select');
          var qty       = $(this).find('.quantity input.qty');
          var _selected = '';
          var selected_qty = parseInt(qty.val());
          if ( select.length > 0 && qty.length > 0 ) {
            var min = 1;
            var max = qty.attr('max');

            for (i = min, len = max; i <= len; i++) { 
              if ( selected_qty === i ) {
                _selected = 'selected';
              } else {
                _selected = '';
              }
              select.append("<option value='"+i+"' "+ _selected +">"+i+"</option>");
            }
            if ( max ) {
              select.addClass('qty-visible');
            }
          }
        });

        $( document.body ).on( 'check_coupon_via_ajax', function(){
          console.warn('coupon added');
        });

        

        $( document.body ).on( 'updated_cart_totals', function(){
          $('body.woocommerce-cart .woocommerce-cart-form td.product-quantity').each(function(){
            var select    = $(this).find('.custom-select');
            var qty       = $(this).find('.quantity input.qty');
            var _selected = '';
            var selected_qty = parseInt(qty.val());
            if ( select.length > 0 && qty.length > 0 ) {
              var min = 1;
              var max = qty.attr('max');
              for (i = min, len = max; i <= len; i++) { 
                if ( selected_qty === i ) {
                  _selected = 'selected';
                } else {
                  _selected = '';
                }
                select.append("<option value='"+i+"' "+ _selected +">"+i+"</option>");
              }
              
              if ( max ) {
                select.addClass('qty-visible');
              }
            }
          });
        });


        $(document).on('change', 'body.woocommerce-cart .woocommerce-cart-form td.product-quantity .custom-select',function(){
          if ( parseInt($(this).val()) ) {
            $(this).closest('td.product-quantity').find('.quantity input.qty').val( $(this).val() );
          }
        });

        $('.tbt-product-details .custom-select').on('change',function(){
          if ( parseInt($(this).val()) ) {
            $(this).closest('.tbt-product-details').find('.woocommerce-variation-add-to-cart .quantity input[name="quantity"]').val( $(this).val() );
          }
        });

        // $('.woocommerce-notices-wrapper').each(function(){
        //   if ( $(this).find('.popup-notice').length > 0 &&  $(this).find('.popup-notice').text() ) {
        //     $(this).addClass('popup-basket');
        //   } else {
        //     $(this).show();
        //   }
        // });

        $(document).on('click','.popup-overlay, a.close-wc-popup',function(e){
          e.preventDefault();
          $(this).closest('.ttt-pnwc-container').find('.ttt-pnwc-close').click();
        });


        

        
        function closeNav() {
          document.getElementById("tbt-shop-filter").style.width = "0";
          document.getElementById("tbt-main-wrap").style.marginLeft = "0";
        }

      },
      lazy_section: function(){
        function isScrolledIntoView($elem) {
          if ( $elem.length > 0 ) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();
            var elemTop = $elem.offset().top;
            var returnvalue = ((elemTop <= docViewBottom - 20) && (elemTop >= docViewTop + 20));
            return returnvalue;
          }
        }

        function init_lazy_section() {    
          $('.lazy-section').each(function(){
            if ( isScrolledIntoView( $(this) ) ) {
              $(this).removeClass('lazy-section');
            }
          });  
        }

        if ( $('.lazy-section').length > 0 ) {
          init_lazy_section();
          $(window).scroll(function () {
            init_lazy_section();
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
